import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7347e932 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _87385a9a = () => interopDefault(import('../pages/AboutUs.vue' /* webpackChunkName: "pages/AboutUs" */))
const _6564ea5f = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _1b5b8e05 = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _15d34251 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _96fad748 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _37618370 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _6445ec22 = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _64b01d90 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _8ead641e = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _7c9de548 = () => interopDefault(import('../pages/Contact.vue' /* webpackChunkName: "pages/Contact" */))
const _2bbf2f3c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _6b693aac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _4a970c2d = () => interopDefault(import('../modules/customer/pages/MyAccount/FastOrder.vue' /* webpackChunkName: "" */))
const _47e9e3db = () => interopDefault(import('../modules/customer/pages/MyAccount/Invoices.vue' /* webpackChunkName: "" */))
const _a340058c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyDownloads.vue' /* webpackChunkName: "" */))
const _a66bab0c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _642040a6 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4a38f22a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _07cb6874 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _7e4022ea = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _3b756ef6 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _9c9e0936 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _433d80a4 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _36a58c6e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _42c5a93e = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _36e0a1e6 = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _16912117 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _67c98b2b = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "pages/Register" */))
const _6588e47a = () => interopDefault(import('../pages/Storyblok.vue' /* webpackChunkName: "pages/Storyblok" */))
const _4d05aa04 = () => interopDefault(import('../pages/thank-you.vue' /* webpackChunkName: "pages/thank-you" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/fr",
    component: _7347e932,
    name: "home___fr"
  }, {
    path: "/nl",
    component: _7347e932,
    name: "home___nl"
  }, {
    path: "/fr/AboutUs",
    component: _87385a9a,
    name: "AboutUs___fr"
  }, {
    path: "/fr/cart",
    component: _6564ea5f,
    name: "cart___fr"
  }, {
    path: "/fr/checkout",
    component: _1b5b8e05,
    name: "checkout___fr",
    children: [{
      path: "billing",
      component: _15d34251,
      name: "billing___fr"
    }, {
      path: "payment",
      component: _96fad748,
      name: "payment___fr"
    }, {
      path: "shipping",
      component: _37618370,
      name: "shipping___fr"
    }, {
      path: "thank-you",
      component: _6445ec22,
      name: "thank-you-checkout___fr"
    }, {
      path: "user-account",
      component: _64b01d90,
      name: "user-account___fr"
    }]
  }, {
    path: "/fr/Cms",
    component: _8ead641e,
    name: "Cms___fr"
  }, {
    path: "/fr/Contact",
    component: _7c9de548,
    name: "Contact___fr"
  }, {
    path: "/fr/customer",
    component: _2bbf2f3c,
    meta: {"titleLabel":"My Account"},
    name: "customer___fr",
    children: [{
      path: "addresses-details",
      component: _6b693aac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___fr"
    }, {
      path: "fast-order",
      component: _4a970c2d,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___fr"
    }, {
      path: "invoices",
      component: _47e9e3db,
      meta: {"titleLabel":"Invoices"},
      name: "customer-invoices___fr"
    }, {
      path: "my-downloads",
      component: _a340058c,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___fr"
    }, {
      path: "my-newsletter",
      component: _a66bab0c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___fr"
    }, {
      path: "my-profile",
      component: _642040a6,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___fr"
    }, {
      path: "my-reviews",
      component: _4a38f22a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___fr"
    }, {
      path: "my-wishlist",
      component: _07cb6874,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___fr"
    }, {
      path: "order-history",
      component: _7e4022ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___fr"
    }, {
      path: "addresses-details/create",
      component: _3b756ef6,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___fr"
    }, {
      path: "/fr/reset-password",
      component: _9c9e0936,
      alias: "/customer/account/createPassword",
      name: "reset-password___fr"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _433d80a4,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___fr"
    }, {
      path: "order-history/:orderId",
      component: _36a58c6e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___fr"
    }]
  }, {
    path: "/fr/Faq",
    component: _42c5a93e,
    name: "Faq___fr"
  }, {
    path: "/fr/ForgotPassword",
    component: _36e0a1e6,
    name: "ForgotPassword___fr"
  }, {
    path: "/fr/Home",
    component: _7347e932,
    name: "Home___fr"
  }, {
    path: "/fr/Page",
    component: _16912117,
    name: "Page___fr"
  }, {
    path: "/fr/Register",
    component: _67c98b2b,
    name: "Register___fr"
  }, {
    path: "/fr/Storyblok",
    component: _6588e47a,
    name: "Storyblok___fr"
  }, {
    path: "/fr/thank-you",
    component: _4d05aa04,
    name: "thank-you___fr"
  }, {
    path: "/nl/AboutUs",
    component: _87385a9a,
    name: "AboutUs___nl"
  }, {
    path: "/nl/cart",
    component: _6564ea5f,
    name: "cart___nl"
  }, {
    path: "/nl/checkout",
    component: _1b5b8e05,
    name: "checkout___nl",
    children: [{
      path: "billing",
      component: _15d34251,
      name: "billing___nl"
    }, {
      path: "payment",
      component: _96fad748,
      name: "payment___nl"
    }, {
      path: "shipping",
      component: _37618370,
      name: "shipping___nl"
    }, {
      path: "thank-you",
      component: _6445ec22,
      name: "thank-you-checkout___nl"
    }, {
      path: "user-account",
      component: _64b01d90,
      name: "user-account___nl"
    }]
  }, {
    path: "/nl/Cms",
    component: _8ead641e,
    name: "Cms___nl"
  }, {
    path: "/nl/Contact",
    component: _7c9de548,
    name: "Contact___nl"
  }, {
    path: "/nl/customer",
    component: _2bbf2f3c,
    meta: {"titleLabel":"My Account"},
    name: "customer___nl",
    children: [{
      path: "addresses-details",
      component: _6b693aac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___nl"
    }, {
      path: "fast-order",
      component: _4a970c2d,
      meta: {"titleLabel":"Fast Order"},
      name: "fast-order___nl"
    }, {
      path: "invoices",
      component: _47e9e3db,
      meta: {"titleLabel":"Invoices"},
      name: "customer-invoices___nl"
    }, {
      path: "my-downloads",
      component: _a340058c,
      meta: {"titleLabel":"My downloads"},
      name: "customer-my-downloads___nl"
    }, {
      path: "my-newsletter",
      component: _a66bab0c,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___nl"
    }, {
      path: "my-profile",
      component: _642040a6,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___nl"
    }, {
      path: "my-reviews",
      component: _4a38f22a,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___nl"
    }, {
      path: "my-wishlist",
      component: _07cb6874,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___nl"
    }, {
      path: "order-history",
      component: _7e4022ea,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___nl"
    }, {
      path: "addresses-details/create",
      component: _3b756ef6,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___nl"
    }, {
      path: "/nl/reset-password",
      component: _9c9e0936,
      alias: "/customer/account/createPassword",
      name: "reset-password___nl"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _433d80a4,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___nl"
    }, {
      path: "order-history/:orderId",
      component: _36a58c6e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___nl"
    }]
  }, {
    path: "/nl/Faq",
    component: _42c5a93e,
    name: "Faq___nl"
  }, {
    path: "/nl/ForgotPassword",
    component: _36e0a1e6,
    name: "ForgotPassword___nl"
  }, {
    path: "/nl/Home",
    component: _7347e932,
    name: "Home___nl"
  }, {
    path: "/nl/Page",
    component: _16912117,
    name: "Page___nl"
  }, {
    path: "/nl/Register",
    component: _67c98b2b,
    name: "Register___nl"
  }, {
    path: "/nl/Storyblok",
    component: _6588e47a,
    name: "Storyblok___nl"
  }, {
    path: "/nl/thank-you",
    component: _4d05aa04,
    name: "thank-you___nl"
  }, {
    path: "/fr/:slug+",
    component: _16912117,
    name: "page___fr"
  }, {
    path: "/nl/:slug+",
    component: _16912117,
    name: "page___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
