/* eslint-disable  */

export default {
  "About us": "Over ons",
  Account: "Account",
  "Add new address": "Nieuw adres toevoegen",
  "Add to compare": "Toevoegen aan vergelijking",
  "Add to Wishlist": "Toevoegen aan verlanglijstje",
  "Additional Information": "Aanvullende informatie",
  "All Orders": "Alle bestellingen",
  "Allow order notifications": "Meldingen van bestellingen toestaan",
  Apply: "Toepassen",
  "Applied Coupon": "Toegepaste coupon",
  "Attention!": "Let op!",
  "Back to home": "Terug naar home",
  "Back to homepage": "Terug naar homepage",
  Billing: "Facturatie",
  "Billing address": "Factuuradres",
  Brand: "Merk",
  Cancel: "Annuleren",
  Cart: "Winkelwagen",
  Categories: "Categorieën",
  Change: "Wijzigen",
  "Change password your account": "Als je het wachtwoord wilt wijzigen om toegang te krijgen tot je account, voer dan de volgende informatie in",
  "Clear all": "Alles wissen",
  Color: "Kleur",
  "Commercial information": "en akkoord gaan met het ontvangen van gepersonaliseerde commerciële informatie van Brand name per e-mail",
  "Contact details updated": "Houd je adressen en contactgegevens bijgewerkt.",
  "Contact us": "Neem contact met ons op",
  "Continue to billing": "Doorgaan naar facturering",
  "Continue to payment": "Doorgaan naar betaling",
  "Continue to shipping": "Doorgaan naar verzending",
  "Cookies Policy": "Cookiebeleid",
  "Create an account": "Maak een account aan",
  "Customer Reviews": "Klantbeoordelingen",
  "Customer service": "Klantenservice",
  Date: "Datum",
  "Default Billing Address": "Standaard factuuradres",
  "Default Shipping Address": "Standaard verzendadres",
  Delete: "Verwijderen",
  Departments: "Afdelingen",
  Description: "Beschrijving",
  "Details and status orders":
    "Controleer de details en status van je bestellingen in de online winkel. Je kunt ook je bestelling annuleren of een retour aanvragen.",
  Discount: "Korting",
  Done: "Klaar",
  Download: "Downloaden",
  "Download all": "Alles downloaden",
  Edit: "Bewerken",
  "Email address": "E-mailadres",
  Empty: "Het lijkt erop dat je nog geen items aan je tas hebt toegevoegd. Begin met winkelen om het te vullen.",
  "Empty bag": "Lege tas",
  "Enjoy your free account": "Geniet van deze voordelen met je gratis account!",
  "Enter promo code": "Voer promotiecode in",
  Feedback: "Je feedback is belangrijk voor ons. Laat ons weten wat we kunnen verbeteren.",
  "Feel free to edit": "Voel je vrij om een van je gegevens hieronder te bewerken zodat je account altijd up-to-date is",
  Filters: "Filters",
  "Find out more": "Lees verder",
  "First Name": "Voornaam",
  "Forgot Password": "Als je je wachtwoord niet meer weet, kun je het opnieuw instellen.",
  "Forgot Password Modal Email": "E-mailadres waarmee je aanmeldt:",
  forgotPasswordConfirmation:
    "Bedankt! Als er een account is geregistreerd met het e-mailadres {0}, vind je een bericht met een link voor het opnieuw instellen van het wachtwoord in je inbox.",
  "Forgotten password?": "Wachtwoord vergeten?",
  "Go back": "Ga terug",
  "Go back shopping": "Terug naar winkelen",
  "Go back to shop": "Terug naar de winkel",
  "Go to checkout": "Ga naar de kassa",
  "Go to cart": "Ga naar de winkelwagen",
  Guarantee: "Garantie",
  Help: "Help",
  "Help & FAQs": "Help en Veelgestelde vragen",
  hide: "verbergen",
  Home: "Thuis",
  "I agree to": "Ik ga akkoord met",
  "I confirm subscription": "Ik bevestig inschrijving",
  "I want to create an account": "Ik wil een account aanmaken",
  "Info after order":
    "Je kunt inloggen op je account met het eerder gedefinieerde e-mailadres en wachtwoord. Op je account kun je je profielgegevens bewerken, de geschiedenis van transacties controleren en je abonnement op de nieuwsbrief bewerken.",
  Instruction1: "Zorg voor mij",
  Instruction2: "Alleen hier voor de wasinstructies?",
  Instruction3: "Ja, dat dachten we al",
  "It was not possible to request a new password, please check the entered email address.":
    "Het was niet mogelijk om een nieuw wachtwoord aan te vragen, controleer het ingevoerde e-mailadres.",
  Item: "Item",
  Items: "Items",
  Kidswear: "Kinderkleding",
  "Last Name": "Achternaam",
  "Let’s start now – we’ll help you": "Laten we nu beginnen - we helpen je.",
  "Log into your account": "Log in op je account",
  Login: "Inloggen",
  "login in to your account": "inloggen op je account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.":
    "Het lijkt erop dat je nog geen items aan de tas hebt toegevoegd. Begin met winkelen om het te vullen.",
  "Looks like you haven’t added any items to the Wishlist.": "Het lijkt erop dat je nog geen items aan het verlanglijstje hebt toegevoegd.",
  "Make an order": "Plaats een bestelling",
  "Manage addresses":
    "Beheer alle adressen die je wilt (werkplek, thuisadres...) Op deze manier hoef je het adres niet handmatig in te voeren bij elke bestelling.",
  "Manage billing addresses":
    "Beheer alle factuuradressen die je wilt (werkplek, thuisadres...) Op deze manier hoef je het factuuradres niet handmatig in te voeren bij elke bestelling.",
  "Manage shipping addresses":
    "Beheer alle verzendadressen die je wilt (werkplek, thuisadres...) Op deze manier hoef je het verzendadres niet handmatig in te voeren bij elke bestelling.",
  "Match it with": "Match het met",
  "Men fashion": "Herenmode",
  Menu: "Menu",
  "My billing and shipping address are the same": "Mijn factuur- en verzendadres zijn hetzelfde",
  "My Cart": "Mijn winkelmandje",
  "No account": "Heb je nog geen account?",
  or: "of",
  "or fill the details below": "of vul de onderstaande gegevens in",
  "Order ID": "Bestel-ID",
  "Order information": "Bestelinformatie",
  "Order No.": "Bestelnummer",
  "Order summary": "Besteloverzicht",
  "Other products you might like": "Andere producten die je misschien leuk vindt",
  "Out of stock": "Uit voorraad",
  Password: "Wachtwoord",
  "Password Changed": "Wachtwoord succesvol gewijzigd. U kunt nu teruggaan naar de startpagina en inloggen.",
  "Pay for order": "Betalen voor bestelling",
  Payment: "Betaling",
  "Payment & Delivery": "Betaling & Bezorging",
  "Payment Method": "Betalingsmethode",
  "Payment Methods": "Betalingsmethoden",
  "Personal details": "Persoonlijke gegevens",
  "Please type your current password to change your email address.": "Typ je huidige wachtwoord om je e-mailadres te wijzigen.",
  Price: "Prijs",
  Privacy: "Privacy",
  "Privacy Policy": "Privacybeleid",
  Product: "Product",
  "Product suggestions": "Productaanbevelingen",
  Products: "Producten",
  "Products found": "Producten gevonden",
  "Purchase terms": "Aankoopvoorwaarden",
  "Quality in the details": "Kwaliteit in de details",
  Quantity: "Hoeveelheid",
  "Read all reviews": "Lees alle beoordelingen",
  "Read and understand": "Ik heb gelezen en begrepen de",
  "Read reviews": "Lees beoordelingen",
  Register: "Registreren",
  "Register today": "Registreer vandaag",
  Remove: "Verwijderen",
  "Remove Address": "Adres verwijderen",
  "Remove from Wishlist": "Verwijderen van verlanglijstje",
  "Repeat Password": "Herhaal wachtwoord",
  "Reset Password": "Wachtwoord resetten",
  "Review my order": "Mijn bestelling controleren",
  "Same as shipping address": "Hetzelfde als het verzendadres",
  "Save changes": "Wijzigingen opslaan",
  "Save for later": "Opslaan voor later",
  "Save Password": "Wachtwoord opslaan",
  Search: "Zoeken",
  "Search for items": "Zoek naar items",
  "Search results": "Zoekresultaten",
  "Sections that interest you": "Secties die je interesseren",
  "See all results": "Bekijk alle resultaten",
  "See more": "Bekijk meer",
  "Select payment method": "Selecteer betaalmethode",
  "Select shipping method": "Selecteer verzendmethode",
  "Send my feedback": "Stuur mijn feedback",
  "Set up newsletter":
    "Stel je nieuwsbrief in en we sturen je elke week informatie over nieuwe producten en trends uit de door jou geselecteerde secties.",
  "Share your look": "Deel je look",
  Shipping: "Verzending",
  "Shipping address": "Verzendadres",
  "Shipping details": "Verzendgegevens",
  "Shipping method": "Verzendmethode",
  Show: "Weergeven",
  "show more": "meer weergeven",
  "Show on page": "Weergeven op pagina",
  "Sign in": "Aanmelden",
  "Size guide": "Maattabel",
  "Sign Up for Newsletter": "Aanmelden voor nieuwsbrief",
  "Sort by": "Sorteren op",
  "Sort: Default": "Standaard",
  "Sort: Name A-Z": "Naam A-Z",
  "Sort: Name Z-A": "Naam Z-A",
  "Sort: Price from high to low": "Prijs van hoog naar laag",
  "Sort: Price from low to high": "Prijs van laag naar hoog",
  "Start shopping": "Begin met winkelen",
  Status: "Status",
  Subscribe: "Abonneren",
  "Subscribe to newsletter": "Abonneren op nieuwsbrief",
  subscribeToNewsletterModalContent:
    "Na aanmelding voor de nieuwsbrief ontvang je speciale aanbiedingen en berichten van VSF via e-mail. We zullen je e-mailadres nooit verkopen of distribueren aan derden. Raadpleeg ons {0}.",
  Subtotal: "Subtotaal",
  "Subtotal price": "Subtotaal prijs",
  "Successful placed order":
    "Je hebt de bestelling succesvol geplaatst. Je kunt de status van je bestelling controleren met onze bezorgstatusfunctie. Je ontvangt een orderbevestigingsmail met details van je bestelling en een link om de voortgang te volgen.",
  "Terms and conditions": "Algemene voorwaarden",
  "Thank you": "Bedankt",
  "Thank You Inbox": "Als het bericht niet in je inbox aankomt, probeer dan een ander e-mailadres dat je hebt gebruikt om je te registreren.",
  Total: "Totaal",
  "Order Total": "Totale bestelling",
  "Total items": "Totaal aantal items",
  "Total price": "Totale prijs",
  "Update password": "Wachtwoord bijwerken",
  "Update personal data": "Persoonlijke gegevens bijwerken",
  "Use your personal data":
    "Bij Brand name hechten we veel belang aan privacykwesties en zijn we toegewijd aan het beschermen van de persoonlijke gegevens van onze gebruikers. Lees meer over hoe we omgaan en gebruikmaken van je persoonlijke gegevens in de",
  "User Account": "Gebruikersaccount",
  View: "Bekijken",
  "View details": "Details bekijken",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Wie we zijn",
  Wishlist: "Verlanglijstje",
  "Women fashion": "Damesmode",
  "You added {product} to your shopping cart.": "Je hebt {product} toegevoegd aan je winkelwagen.",
  "You are not authorized, please log in": "Je bent niet gemachtigd, log alsjeblieft in",
  "You can unsubscribe at any time": "Je kunt je op elk moment afmelden",
  "You currently have no orders": "Je hebt momenteel geen bestellingen",
  "You haven’t searched for items yet": "Je hebt nog niet naar items gezocht.",
  "Your bag is empty": "Je tas is leeg",
  "Your current email address is": "Je huidige e-mailadres is",
  "Your email": "Je e-mail",
  "The email field must be a valid email": "Het e-mailveld moet een geldig e-mailadres zijn",
  "You have submitted no reviews": "Je hebt geen beoordelingen ingediend",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":
    "De aanmelding bij het account was onjuist of je account is tijdelijk uitgeschakeld. Wacht even en probeer het later opnieuw.",
  "A customer with the same email address already exists in an associated website.":
    "Een klant met hetzelfde e-mailadres bestaat al op een gerelateerde website.",
  "Invalid email": "Ongeldig e-mailadres",
  "SUMMER COLLECTION {year}": "ZOMERCOLLECTIE {year}",
  "Colorful summer dresses are already in store": "Kleurrijke zomerjurken liggen al in de winkel",
  "Learn more": "Meer informatie",
  Dresses: "Jurken",
  "Cocktail & Party": "Cocktail & Feest",
  "Linen Dresses": "Linnen Jurken",
  "T-Shirts": "T-Shirts",
  "The office life": "Het kantoorleven",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.":
    "Vind prachtige cocktailjurken en feestjurken voor vrouwen. Val op in kanten en metallic cocktailjurken van al je favoriete merken.",
  "Shop now": "Nu winkelen",
  "The Office Life": "Het kantoorleven",
  "Summer Sandals": "Zomersandalen",
  "Eco Sandals": "Eco-sandalen",
  "Subscribe to Newsletters": "Abonneer op nieuwsbrieven",
  "Be aware of upcoming sales and events. Receive gifts and special offers!":
    "Wees op de hoogte van aankomende verkopen en evenementen. Ontvang geschenken en speciale aanbiedingen!",
  "Fashion to take away": "Mode om mee te nemen",
  "Download our application to your mobile": "Download onze applicatie naar je mobiel",
  "Share Your Look": "Deel je look",
  "My Account": "Mijn Account",
  "My profile": "Mijn profiel",
  "Personal Details": "Persoonlijke gegevens",
  "Addresses details": "Adresgegevens",
  "My newsletter": "Mijn nieuwsbrief",
  "Log out": "Uitloggen",
  "My reviews": "Mijn beoordelingen",
  "Order history": "Bestelgeschiedenis",
  "Order details": "Besteldetails",
  "My wishlist": "Mijn verlanglijstje",
  "Password change": "Wachtwoordwijziging",
  "Personal data": "Persoonlijke gegevens",
  "Your e-mail": "Je e-mail",
  "Current Password": "Huidig wachtwoord",
  "You are not authorized, please log in.": "Je bent niet gemachtigd, log alsjeblieft in.",
  "Go To Product": "Ga naar product",
  "Change to list view": "Wijzig naar lijstweergave",
  "Change to grid view": "Wijzig naar rasterweergave",
  Returns: "Retourneren",
  "My orders": "Mijn bestellingen",
  "Add the address": "Voeg het adres toe",
  "Set as default shipping": "Instellen als standaard verzending",
  "Set as default billing": "Instellen als standaard facturering",
  "House/Apartment number": "Huis-/appartementsnummer",
  "Street Name": "Straatnaam",
  City: "Stad",
  "State/Province": "Staat/Provincie",
  "Zip-code": "Postcode",
  Country: "Land",
  "Phone number": "Telefoonnummer",
  "Please select a country first": "Selecteer eerst een land",
  "This field is required": "Dit veld is verplicht",
  "The field should have at least 2 characters": "Het veld moet minimaal 2 tekens bevatten",
  "The field should have at least 4 characters": "Het veld moet minimaal 4 tekens bevatten",
  "The field should have at least 8 characters": "Het veld moet minimaal 8 tekens bevatten",
  "New Password": "Nieuw wachtwoord",
  "New Products": "Nieuwe producten",
  "There are no shipping methods available for this country. We are sorry, please try with different country.":
    "Er zijn geen verzendmethoden beschikbaar voor dit land. Onze excuses, probeer het met een ander land.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.":
    "Er is een fout opgetreden bij het ophalen van de verzendmethoden. Onze excuses, probeer het met andere verzendgegevens.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.":
    "Er is een fout opgetreden bij het selecteren van deze verzendmethode. Onze excuses, probeer het met een andere verzendmethode.",
  "We can't find products matching the selection.": "We kunnen geen producten vinden die overeenkomen met de selectie.",
  "Page not found": "Pagina niet gevonden",
  "Back to Home page": "Terug naar de startpagina",
  "An error occurred": "Er is een fout opgetreden",
  AllProductsFromCategory: "Alle {categoryName}",
  "Show more": "Meer weergeven",
  "Show less": "Minder weergeven",
  Yes: "Ja",
  No: "Nee",
  "Apply filters": "Filters toepassen",
  "The coupon code isn't valid. Verify the code and try again.": "De couponcode is niet geldig. Controleer de code en probeer het opnieuw.",
  From: "Van",
  To: "Tot",
  "Your customization": "Je aanpassing",
  "Passwords don't match": "Wachtwoorden komen niet overeen",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":
    "Het wachtwoord moet minimaal 8 tekens lang zijn en moet minstens bevatten: 1 hoofdletter of kleine letter, 1 cijfer of een speciaal teken (bijv. , . _ & ? enz.)",
  "Show all products": "Alle producten tonen",
  "Select previously saved address": "Selecteer eerder opgeslagen adres",
  "Enter different address": "Voer een ander adres in",
  "You must confirm your account. Please check your email for the confirmation link.":
    "Je moet je account bevestigen. Controleer je e-mail voor de bevestigingslink.",
  "Change Store": "Winkel wijzigen",
  "Choose Currency": "Valuta kiezen",
  "Add a review": "Voeg een beoordeling toe",
  "Add to cart": "Toevoegen aan winkelwagen",
  Title: "Titel",
  Name: "Naam",
  Review: "Beoordeling",
  "Add review": "Beoordeling toevoegen",
  "Are you sure you would like to remove this item from the shopping cart?": "Weet je zeker dat je dit item uit de winkelwagen wilt verwijderen?",
  "Your cart is empty": "Je winkelwagen is leeg",
  "Keep Shopping": "Verder winkelen",
  "Total shipping": "Totale verzendkosten",
  "Continue shopping": "Doorgaan met winkelen",
  "Orders history": "Bestelgeschiedenis",
  "Prev page": "VORIGE",
  "Next page": "VOLGENDE",
  By: "Van",
  Until: "Tot",
  "Retail Discount": "Retail Discount",
  discount: "korting",
  "Click here": "klik hier",
  "business customer?": "zakelijke klant?",
  "Extra information": "Extra informatie",
  Downloads: "Downloads",
  "per person": "per persoon",
  "Read more": "Lees meer",
  "Pick a date": "Kies een datum:",
  "Name of attendant": "Naam van de cursist:",
  "Enter full name": "Voer de volledige naam in *",
  Address: "Adres",
  Phone: "Telefoon",
  Fax: "Faxen",
  "E-mail": "E-mail",
  Community: "Gemeenschap",
  "Your Name": "Uw naam",
  "Your Surname": "Uw achternaam",
  "Privacy policy": "Privacybeleid",
  Subject: "Onderwerp",
  "read and accepted": "gelezen en geaccepteerd",
  Submit: "Verzenden",
  Show: "Show",
  "per page": "per pagina",
  "Partner registration form": "Partner registratieformulier",
  "Company details": "Bedrijfsgegevens",
  "Chamber of Commerce nr.": "Kamer van Koophandel nr.",
  Website: "Website",
  "Main address details": "Hoofdadresgegevens",
  Gender: "Geslacht",
  Male: "Man",
  Female: "Vrouw",
  "Non-binary": "Non-binair",
  "Account preferences": "Accountvoorkeuren",
  "Password does not match": "Wachtwoord komt niet overeen",
  "Confirm Password": "Bevestig wachtwoord",
  "Send me personalized offers and other information in the future.": "Stuur mij gepersonaliseerde aanbiedingen en andere informatie in de toekomst.",
  "Apply for a dealer account": "Aanvragen voor een dealeraccount",
  "Company name": "Bedrijfsnaam",
  "VAT registration nr.": "BTW-registratienr.",
  "Street name": "Straatnaam",
  Number: "Nummer",
  "Zip code": "Postcode",
  "First name": "Voornaam",
  "Last name": "Achternaam",
  "Upload CSV of Products": "CSV-bestand van producten uploaden",
  "Fast Order": "Snelle bestelling",
  "Upload CSV file and add products in bulk": "Upload CSV-bestand en voeg producten in bulk toe",
  "Choose a file": "Kies een bestand",
  "Download CSV Example": "Voorbeeld CSV downloaden",
  "This product is not in stock": "Dit product is niet op voorraad",
  "Kindly add the date and the student's name.": "Gelieve de datum en de naam van de student toe te voegen.",
  Details: "Details",
  "Please complete order of your training product first": "Voltooi eerst de bestelling van je trainingsproduct",
  "Please complete order with regular products before buying training":
    "Voltooi eerst de bestelling met reguliere producten voordat je training aanschaft",
  "It appears that the file you uploaded does not contain the correct data. Please double-check":
    "Het lijkt erop dat het bestand dat je hebt geüpload niet de juiste gegevens bevat; controleer het alsjeblieft opnieuw.",
  "per product": "per product",
  "Reset your password": "Herstel uw wachtwoord",
  "Change password": "Wijzig wachtwoord",
  "Please use one uppercase and one number in your password.": "Gebruik alstublieft één hoofdletter en één cijfer in uw wachtwoord.",
  "There are no source items with the in stock status": "De optie die je probeerde toe te voegen is niet op voorraad, kies alsjeblieft een andere.",
  "Pick an option": "Kies een optie.",
  "Your message": "Jouw bericht",
  Category: "Categorie",
  Invoices: "Facturen",
  "Invoice to": "Factureren aan",
  "Invoice Amount": "Factuurbedrag",
  "Download invoice": "Factuur downloaden",
  "Invoice Number": "Factuurnummer",
  "Some of the products in your cart are out of stock, please remove them before you can continue":
    "Sommige producten in je winkelwagen zijn niet op voorraad, verwijder ze voordat je kunt doorgaan.",
  "{0} has been successfully removed from your cart": "{0} is succesvol uit uw winkelwagen verwijderd",
};
